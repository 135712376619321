<template>
  <div>
    <div class="w1200">
      <el-form class="form_box" :inline="true">
        <el-form-item>
          <el-select v-model="params.position" placeholder="按职位查找" class="ipt">
            <el-option
              v-for="item in postList"
              :key="item.dict_value"
              :label="item.dict_label"
              :value="item.dict_value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="params.serviceId" placeholder="按业务领域查找" class="ipt">
            <el-option
              v-for="item in serveList"
              :key="item.id"
              :label="item.service_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="params.name" placeholder="按律师姓名查找" class="ipt"></el-input>
        </el-form-item>
        <el-form-item class="subbtn">
          <p @click="search">提 交</p>
        </el-form-item>
      </el-form>
      <ul class="list_box">
        <li class="list_item" v-for="item in list" :key='item.id' @click="goDetails(item)">
          <el-image style="width: 190px; height: 270px" :src="baseUrlImg+'/image?fileName='+item.photo" fit="fill"></el-image>
          <p class="name">{{item.name}}</p>
          <p class="education">{{item.education}}</p>
          <!-- <div>
            <span class="name">{{item.name}}</span>
            <p class="pos flex_box"><span class="iconfont icon-weizhi"></span>{{item.address}}</p>
          </div>
          <span class="profession">{{item.education}}</span>
          <div class="flex1">
            <span class="label">业务领域：</span>
            <p class="info">{{item.serviceName}}</p>
          </div>
          <span class="cv">CV</span> -->
        </li>
      </ul>
      <pagination ref='pagination' :total='total' @currentChange='currentChange'></pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      total:0, // 总条数
      postList:[],// 职位列表
      serveList:[], // 业务领域列表
      list:[], //专业人员列表
      params:{
        position:'',
        serviceId:'',
        name:'',
        addressId:'',
        pageNum:1,
        pageSize:110
      },// 参数
    }
  },
  mounted() {
    this.params.position = this.$route.query.position || ''
    this.params.serviceId = this.$route.query.serviceId || ''
    if (this.params.serviceId) {
      this.params.serviceId = Number(this.params.serviceId)
    }
    this.params.name = this.$route.query.name || ''
    this.getPostList()
    this.getServeList()
    this.getList()
  },
  methods:{
    getPostList() {
      this.$api.getAllPosition().then(res=>{
        this.postList = res.data
      })
    },
    getServeList() {
      this.$api.getAllServiceArea().then(res=>{
        this.serveList = res.data
      })
    },
    getList() {
      this.$api.getProfessionalByPage(this.params).then(res=>{
        this.list = res.rows
        this.total = res.total
      })
    },
    peopleChange(id) {
      this.params.addressId = id
      this.params.pageNum = 1
      this.getList()
    },
    search() {
      this.params.pageNum = 1
      this.getList()
    },
    currentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    goDetails(item) {
      this.$router.push({
        path:'/professionalDetails/'+item.id,
        query:{
          type:this.$route.query.type
        }
      })
    }
  }
}
</script>

<style lang='stylus' scoped>
@import '~@/assets/stylus/variable'
  .ipt
    width: 300px
  .subbtn
    float: right
    p
      width: 120px
      height: 40px
      text-align center
      line-height 40px
      background: $theme-color
      color #fff
      font-size 21px
      cursor pointer
  .list_box
    // background: #f4f4f4
    padding 30px 0
    display: flex
    align-items: center
    flex-wrap: wrap
    .list_item
      width: 192px
      margin 0 60px 60px 0
      // padding 30px 0
      // border-bottom 1px solid #bbb
      cursor pointer
      background: #f0f0f0
      &:nth-child(5n)
        margin-right 0
      &:hover
        .name
          color $theme-color
      .name
        font-size: 18px;
        line-height: 30px;
        height: 30px;
        overflow: hidden
        text-align: center
      .education
        font-size: 14px;
        line-height: 25px;
        height: 25px;
        overflow: hidden;
        border-top: 1px solid #ddd;
        color: #999;
        text-align: center
      .pos
        font-size 16px
        margin-top 15px
        .iconfont
          color: $theme-color
      .profession
        font-size 16px
        margin 0 30px
      .label
        font-size 14px
        color #666
        line-height 32px
      .info
        font-size 16px
        line-height 32px
        text-overflow: ellipsis
        overflow: hidden
        white-space nowrap
      .cv
        font-size 20px
        font-weight bold
        color: $theme-color
        border-bottom 2px solid $theme-color
        padding 10px 5px
        margin-left: 30px
</style>